import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";

class FooterSection extends Component {
    state = {
        data: {},
        iconList: [],
        footerList_1: [],
        footerList_2: []
      }
      
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.themeOneFooterSection,
                    iconList: res.data.themeOneFooterSection.iconList,
                    footerList_1: res.data.themeOneFooterSection.footerList_1,
                    footerList_2: res.data.themeOneFooterSection.footerList_2
                })
            })
            .catch(err => console.log(err))
      }
    render() {
        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/#">
                                <img className="logo" src="/img/vaptaSystems.png" alt="" />
                                </a>
                                <p className="mt-2 mb-3">{this.state.data.text}</p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {this.state.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} className={item.link} href={item.href} target="_blank" >
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_1}</h3>
                                <ul>
                                    <li className="py-2"><a href="/">Home</a></li>
                                    <li className="py-2"><a href="#aboutUs">About us</a></li>
                                    {/* <li className="py-2"><a href="/blog-two-column">Blog</a></li> */}
                                    <li className="py-2"><a href="#contact">Contact</a></li>
                                </ul>
                            </div>
                            </div>

                            <div className="col-12 col-sm-6 col-lg-4">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{this.state.data.linkText_2}</h3>
                                <a href={this.state.data.href} target="_blank">
                                    <div className={this.state.data.headset}></div>
                                </a>
                            </div>
                            </div>
                            
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2021 Vapta Systems All rights reserved.</div>
                                {/* Copyright Right */}
                                <div className="copyright-right">Vapta Systems LTD</div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
export default FooterSection;