import React from "react";
// import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { BrowserRouter as Switch } from "react-router-dom";
import { HashRouter as Router, Route } from 'react-router-dom';


// importing all the themes
import Mainpage from "../themes/Homepage";
import BlogTwoColumn from "../components/Blogs/BlogTwoColumn";
import BlogDetailsLeftSidebar from "../components/Blogs/BlogDetailsLeftSidebar";
import Reviews from "../components/ReviewSection/ReviewTwo";
import FleetManagementPage from "../components/VaptaFleetManagement/FleetManagementPage";
import PartsPage from "../components/VaptaParts/PartsPage";
import DefectPage from "../components/VaptaDefect/DefectPage";
import TelematicsPage from "../components/VaptaTelematics/TelematicsPage";
import TrackingPage from "../components/VaptaTrackingDevices/TrackingDevicesPage";
import InsurancePage from "../components/VaptaInsurance/InsurancePage";
import InspectorPage from "../components/VaptaInspector/InspectorPage";
import CareersPage from "../components/Careers/CareersPage";
import CareersPost from "../components/Careers/CareersPost";
import Faq from "../components/FaqSection/Faq";
import ContactPage from "../components/ContactSection/ContactPage";

// Initial load is 'Mainpage' then these are subsequent routes from the navbar
class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Mainpage} />
            <Route path="/blog-two-column" component={BlogTwoColumn} />
            <Route path="/blog-details-left-sidebar" component={BlogDetailsLeftSidebar} />
            <Route path="/reviews" component={Reviews} />
            <Route path="/FleetManagementPage" component={FleetManagementPage} />
            <Route path="/PartsPage" component={PartsPage} />
            <Route path="/DefectPage" component={DefectPage} />
            <Route path="/TelematicsPage" component={TelematicsPage } />
            <Route path="/TrackingPage" component={TrackingPage } />
            <Route path="/InsurancePage" component={InsurancePage } />
            <Route path="/CareersPage" component={CareersPage } />
            <Route path="/CareersPost" component={CareersPost } />
            <Route path="/InspectorPage" component={InspectorPage } />
            <Route path="/faq" component={Faq} />
            <Route path="/contact-page" component={ContactPage} />
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;