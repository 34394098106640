import React, { Component } from 'react';
import HeaderAlt from '../HeaderSection/HeaderAlt';
import Breadcrumb from './Breadcrumb';
import Blog from './Blog';
import FooterSection from '../FooterSection/Footer';

class BlogTwoColumn extends Component {
    render() {
        return (
            <div className="blog">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <HeaderAlt imageData={"/img/vaptaSystems.png"} />
                    <Breadcrumb/>
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <Blog />
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default BlogTwoColumn;