import React, { Component } from 'react';

const initData = {
    heading: "VAPTA Telematics",
    content: "VAPTA telematics provides a birds eyes view of your fleet, powered by rich telemetry you can operating expenses with state-of-the-art business intelligence.",
    bulletData : [
        {
            id: 1,
            text: "One Platform For Your Fleet Management."
        },
        {
            id: 2,
            text: "Defect Reporting."
        },
        {
            id: 3,
            text: "PMI Inspections."
        },
        {
            id: 4,
            text: "Vehicle Telematics and Driver Reporting."
        },
        {
            id: 5,
            text: "Workshop & VOR Control."
        },
        {
            id: 6,
            text: "Procurement and Parts Analysis."
        },
    ],
    subHeader: "Increase fleet efficiency with rich telemetry. Cut operating expenses with state-of-the-art business intelligence.",
    btnText: "Book a demo",
    heroThumb: "/img/telematicsLaptop2.png"
}

class HeroSection extends Component {
    state = {
        data: {},
        bulletData: []
    }
    componentDidMount(){
        this.setState({
            data: initData,
            bulletData: initData.bulletData
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-inherit h-100vh overflow-hidden">
                <div className="shapes-container">
                    <div className="bg-shape" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-6">
                        <div className="welcome-intro">
                        <h1>{this.state.data.heading}</h1>
                        <p className="my-4">{this.state.data.content}</p>
                        <ul className="bulletPoints">
                            {this.state.bulletData.map((item, idx) => {
                                return( 
                                    <li className="heroList" key={`fth_${idx}`}>
                                        {item.text}
                                    </li>
                                );
                            })}
                        </ul>
                        <h5 className="my-4 completeControl">{this.state.data.subHeader}</h5>
                        <a href="#contact" className="btn">{this.state.data.btnText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        {/* Welcome Thumb */}
                        <div className="welcome-thumb">
                        <img src={this.state.data.heroThumb} alt="" id="inspectorTablet"/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroSection;