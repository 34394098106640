import React, { Component } from 'react';
import HeaderAlt from '../HeaderSection/HeaderAlt';
import TitleBoard from './TitleBoard';
import Jobs from './Jobs';
import WelcomeJob from './WelcomeJob';
import CareerForm from '../ContactSection/Career';
import FooterSection from '../FooterSection/Footer';

class Careers extends Component {
    render() {
        return (
            <div className="careers">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <HeaderAlt imageData={"/img/vaptaSystems.png"} />
                    <TitleBoard />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <WelcomeJob />
                        </div>
                        <CareerForm />
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default Careers;