import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";

class ServiceSection extends Component {
    state = {
        data: {},
        serviceData: []
    }
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.DefectService,
                    serviceData: res.data.DefectService.serviceData
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="vaptaParts" className="section service-area bg-inherit overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">
                        <h2 className="mb-4">{this.state.data.headingTwo}</h2>
                        {/* Service List */}
                        <ul className="service-list">
                            {this.state.serviceData.map((item, idx) => {
                                return(
                                    <div key={`st_${idx}`}>
                                        {/* Single Service */}
                                        <li className="single-service media py-2">
                                        <div className="service-icon pr-4">
                                            <span><i className={item.iconClass} /></span>
                                        </div>
                                        <div className="service-text media-body">
                                            <p>{item.text}</p>
                                        </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <a href="#contact" className="btn btn-bordered mt-4">{this.state.data.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2 d-none d-md-block">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                            <img src={this.state.data.thumbTwo} alt="" id="vaptaDefectsPhone"/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServiceSection;