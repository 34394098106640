import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import InsuranceHero from '../HeroSection/InsuranceHero';
import InsuranceFeature from '../Features/InsuranceFeature';
import InsuranceService from '../ServiceSection/InsuranceService';
import InsuranceDiscover from '../DiscoverSection/InsuranceDiscover';
import InsuranceWork from '../WorkSection/InsuranceWork';
import QuoteSection from '../ContactSection/Quote';
import FooterSection from '../FooterSection/Footer';


class PartsPage extends Component {
      render() {
            return (
                <div className="homepage-2">
                    {/*====== Scroll To Top Area Start ======*/}
                    <div id="scrollUp" title="Scroll To Top">
                        <i className="fas fa-arrow-up" />
                    </div>
                    {/*====== Scroll To Top Area End ======*/}
                    <div className="main">
                        <Header imageData={"/img/vaptaSystems.png"} />
                        <InsuranceHero />
                        <InsuranceFeature />
                        <InsuranceService />
                        <InsuranceWork />
                        <InsuranceDiscover />
                        <QuoteSection />
                        <FooterSection />
                    </div>
                </div>
            );
        }
}

export default PartsPage;