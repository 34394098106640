import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";

class Sidebar extends Component {
    state = {
        data: {},
        widgetData_1: []
    }
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.themeOneSidebarData,
                    widgetData_1: res.data.themeOneSidebarData.widgetData_1
                })
                console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <div>
                <aside className="sidebar">
                    {/* Single Widget */}
                    <div className="single-widget">
                        {/* Category Widget */}
                        <div className="accordions widget catagory-widget" id="cat-accordion">
                        <div className="single-accordion blog-accordion">
                            <h5>
                            <a role="button" className="collapse show text-uppercase d-block p-3" data-toggle="collapse" href="#accordion1">{this.state.data.widgetTitle_1}
                            </a>
                            </h5>
                            {/* Category Widget Content */}
                            <div id="accordion1" className="accordion-content widget-content collapse show" data-parent="#cat-accordion">
                                {/* Category Widget Items */}
                                <ul className="widget-items">
                                    {this.state.widgetData_1.map((item, idx) => {
                                        return(
                                            <li key={`wdo_${idx}`}><a href="" className="d-flex p-3"><span>{item.text}</span><span className="ml-auto">{item.content}</span></a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </aside>
            </div>
        );
    }
}

export default Sidebar;