import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import TelematicsHero from '../HeroSection/TelematicsHero';
import TelematicsFeature from '../Features/TelematicsFeature';
import TelematicsService from '../ServiceSection/TelematicsService';
import TelematicsDiscover from '../DiscoverSection/TelematicsDiscover';
import TelematicsWork from '../WorkSection/TelematicsWork';
import TelematicsPhoto from '../PhotoSection/TelematicsPhoto';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';


class PartsPage extends Component {
      render() {
            return (
                <div className="homepage-2">
                    {/*====== Scroll To Top Area Start ======*/}
                    <div id="scrollUp" title="Scroll To Top">
                        <i className="fas fa-arrow-up" />
                    </div>
                    {/*====== Scroll To Top Area End ======*/}
                    <div className="main">
                        <Header imageData={"/img/vaptaSystems.png"} />
                        <TelematicsHero />
                        <TelematicsFeature />
                        <TelematicsService />
                        <TelematicsWork />
                        <TelematicsDiscover />
                        <TelematicsPhoto />
                        <ContactSection />
                        <FooterSection />
                    </div>
                </div>
            );
        }
}

export default PartsPage;