import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import DefectHero from '../HeroSection/DefectHero';
import FeatureSection from '../Features/DefectFeature';
import DefectService from '../ServiceSection/DefectService';
import DefectDiscover from '../DiscoverSection/DefectDiscover';
import DefectWork from '../WorkSection/DefectWork';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';


class FleetManagements extends Component {
      render() {
            return (
                <div className="homepage-2">
                    {/*====== Scroll To Top Area Start ======*/}
                    <div id="scrollUp" title="Scroll To Top">
                        <i className="fas fa-arrow-up" />
                    </div>
                    {/*====== Scroll To Top Area End ======*/}
                    <div className="main">
                        <Header imageData={"/img/vaptaSystems.png"} />
                        <DefectHero />
                        <FeatureSection />
                        <DefectDiscover />
                        <DefectWork />
                        <DefectService />
                        <ContactSection />
                        <FooterSection />
                    </div>
                </div>
            );
        }
}

export default FleetManagements;