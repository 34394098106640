import React, { Component } from 'react';

class Header extends Component {
    render() {
        return (
            <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
                <div className="container position-relative">
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-regular logo" src={this.props.imageData} alt="brand-logo" />
                        <img className="navbar-brand-sticky logo" src="/img/vaptaSystems.png" alt="sticky brand-logo" />
                    </a>
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className="navbar-inner">
                        {/*  Mobile Menu Toggler */}
                        <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <nav>
                            <ul className="navbar-nav" id="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Home</a>
                                </li>

                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="/FleetManagementPage" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">VAPTA Fleet management</a>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

                                        {/* <li>
                                            <a className="dropdown-item" href="/FleetManagementPage">VAPTA fleet management</a>
                                        </li> */}
                                        <li>
                                            <a className="dropdown-item" href="/DefectPage">Vehicle defect</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/InspectorPage">Vehicle inspector</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/TelematicsPage">VAPTA fleet telematics</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/TrackingPage">VAPTA trackers for security</a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="/InsurancePage">VAPTA insurance<span className="badge badge-pill badge-warning ml-2">New</span></a>
                                        </li>
                                    </ul>
                                </li> 
                                <li className="nav-item">
                                    <a className="nav-link" href="/PartsPage">Vapta Procurement</a>
                                    {/* <Link className="text-white" to="/PartsPage">Vapta Parts</Link> */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/">About</a>
                                    {/* <Link className="text-white" to="/">About</Link> */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/CareersPage">Careers</a>
                                    {/* <Link className="text-white" to="/">About</Link> */}
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/">Contact</a>
                                    {/* <Link className="text-white" to="/">Contact</Link> */}
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
        </header>
        );
    }
}

export default Header;