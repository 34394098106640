import React, { Component } from 'react';

class FaqSection extends Component {
    
    render() {
        return (
            <section className="section faq-area ptb_100">
                <div className="container">       
                    <div className="faq-container">

                        <div className="faq">
                            <h3 className="faq-title">If my business grows and I need new features what do I do?</h3>
                            <p className="faq-text">Speak to one of our engineers who will be happy to talk through a development plan for any additional personal features that can benefit your business.</p>

                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>

                        </div>

                        <div className="faq">
                            <h3 className="faq-title">How does the free trial work?</h3>
                            <p className="faq-text">Try our software for 30 days free of charge with all features unlocked and no commitment. Once you have trialed VAPTA you can choose which features are of greater value to you and will need to add payment information or be invoiced to continue using VAPTA.</p>

                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>

                        <div className="faq">
                            <h3 className="faq-title">What happens if I need day to day IT support with the software?</h3>
                            <p className="faq-text">We offer a free demo before signing up then after you are up and running we offer a managed IT support plan to help you when needed.</p>

                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>

                        <div className="faq">
                            <h3 className="faq-title">How do I know my fleets information is safe?</h3>
                            <p className="faq-text">All of your data is safely secured in the cloud with the most up to date security protocals.</p>

                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>

                        <div className="faq">
                            <h3 className="faq-title">Who can access my data?</h3>
                            <p className="faq-text">Your fleets data can only be accessed by the admin account set to your companies profile ensuring only authorised personal see sensitive data.</p>
                                        
                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>

                        <div className="faq">
                            <h3 className="faq-title">Is there anything to download or install?</h3>
                            <p className="faq-text">No, VAPTA's software is web-based and runs in the cloud and can be accessible by all modern browsers .</p>
                                                    
                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>

                        <div className="faq">
                            <h3 className="faq-title">What happens if I add or remove vehicles?</h3>
                            <p className="faq-text">Adding vehicles is no problem simply enter the reg and add to your fleet. Any vehicles that have been removed can be archived, however you will not pay for these vehicles you will only pay per active vehicle in your fleet.</p>
                                                    
                            <button className="faq-toggle">
                                <i className="fas fa-chevron-down"></i>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}

export default FaqSection;