import React, { Component } from 'react';

const initData = {
    heading: "VAPTA Vehicle Security Trackers",
    content: "VAPTA Trackers is our hardware solution to secure your precious cargo. Our trackers are portable and magnetic allowing you to use them multiple times for different purposes. Alerts can be sent if there is any discrepancy with your cargo.",
    bulletData : [
        {
            id: 1,
            text: "Insurance Approved Trackers."
        },
        {
            id: 2,
            text: "Driver behaviour and vehicle travel history."
        },
        {
            id: 3,
            text: "Remote Vehicle Immobilisation."
        },
        {
            id: 4,
            text: "No Upfront Fee - Contract Based GPS systems."
        },
        {
            id: 5,
            text: "Live Tracking Data."
        },
        {
            id: 6,
            text: "Magnetic Gps Tracker."
        },
    ],
    subHeader: "View your fleets movements with a sophisticated tracking system.",
    btnText: "Book a demo",
    heroThumb: "/img/telematicsLaptop2.png"
}

class HeroSection extends Component {
    state = {
        data: {},
        bulletData: [],
    }
    componentDidMount(){
        this.setState({
            data: initData,
            bulletData: initData.bulletData,
        })
    }
    render() {
        return (
            <section id="home" className="section welcome-area bg-inherit h-100vh overflow-hidden">
                <div className="shapes-container">
                    <div className="bg-shape" />
                </div>
                <div className="container h-100">
                    <div className="row align-items-center h-100">
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-6">
                        <div className="welcome-intro">
                        <h1>{this.state.data.heading}</h1>
                        <ul className="bulletPoints">
                            {this.state.bulletData.map((item, idx) => {
                                return( 
                                    <li className="heroList" key={`fth_${idx}`}>
                                        {item.text}
                                    </li>
                                );
                            })}
                        </ul>
                        <h5 className="my-4 completeControl">{this.state.data.subHeader}</h5>
                        {/* <p className="my-4">{this.state.data.content}</p> */}
                        <a href="#contact" className="btn">{this.state.data.btnText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        {/* Welcome Thumb */}
                        <div className="welcome-thumb">
                        <img src={this.state.data.heroThumb} alt="" id="inspectorTablet"/>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroSection;