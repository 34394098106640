import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";


class ContactSection extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.photoSection
                })
                console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-9">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                            <h2 className="text-capitalize smaller">{this.state.data.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-5 lorryTelematics">
                        <img src={this.state.data.photo} alt=""/>
                    </div>
                    
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;