import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";

class DiscoverSection extends Component {
    state = {
        data: {},
        discoverData: [],

    }
      componentDidMount(){
       axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.TrackersDiscover,
                    discoverData: res.data.TrackersDiscover.discoverData,

                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="vaptaDefect" className="section discover-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1" id="vaptaTracker2">
                        {/* Discover Thumb */}
                        <div className="service-thumb discover-thumb mx-auto text-center mt-5 mt-lg-0">
                            <img src={this.state.data.thumbTwo} alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        {/* Discover Text */}
                        <div className="discover-text px-0 px-lg-4 pt-4 pt-lg-0">
                        <h2 className="pb-4">{this.state.data.headingTwo}</h2>
                        {/* Check List */}
                        <ul className="check-list">
                            {this.state.discoverData.map((item, idx) => {
                                return(
                                    <div key={`dt_${idx}`}>
                                        <li className="py-2">
                                            {/* List Box */}
                                            <div className="list-box media">
                                                <div className="service-icon pr-4">
                                                      <span><i className={item.iconClass} /></span>
                                                </div>
                                                <span className="media-body pl-2">{item.text}</span>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                            
                        </ul>
                        <a href="#contact" className="btn btn-bordered mt-4">{this.state.data.buttonText}</a>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default DiscoverSection;