import React, { Component } from 'react';
import ContactForm from './CareerForm';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";


class ContactSection extends Component {
    state = {
        data: {},
        iconList: []
    }
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.QuoteSection,
                    iconList: res.data.QuoteSection.iconList
                })
            //     console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-10">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize smaller">Register interest</h2>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-center">
                    
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <ContactForm />
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;