import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";

var firebaseConfig = {
      apiKey: "AIzaSyCKpczwjL2txoiR27nW8ejB5YSw6Ude9RI",
      authDomain: "vapta-website-v2.firebaseapp.com",
      projectId: "vapta-website-v2",
      storageBucket: "vapta-website-v2.appspot.com",
      messagingSenderId: "265019579752",
      appId: "1:265019579752:web:d89cfe65a579953d308add",
      measurementId: "G-V8Y3GCBG91"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore()
db.settings({ timestampsInSnapshots: true })

class ContactForm extends Component {

  constructor(props) {
    super(props)
    this.myForm = React.createRef()
  }

  state = {
    name: '',
    email: '',
    subject: '',
    message: ''
  }

  changHandler = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
}


submitHangler = (e) => {
      e.preventDefault();
      this.myForm.current.reset()
      this.setState({
            name: '',
            email: '',
            subject: '',
            message: ''
      })
      
      console.log(this.state);
      console.log(this.state.name);
      console.log(db);

      db.collection('messages').add({
            name: this.state.name,
            email: this.state.name,
            subject: this.state.subject,
            message: this.state.message
      }).then(() => { console.log(this.state.message) })
}


  render() {
    return (
      <div className="contact-box text-center">
        <form ref={this.myForm} onSubmit={this.submitHangler} className="contact-form" id="contactForm" noValidate="novalidate">
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Name"
                required="required"
                onChange={this.changHandler}
                value={this.state.name}
                />
              </div>

              <div className="form-group">
                <input
                type="email"
                className="form-control"
                name="email"
                placeholder="Your Email"
                required="required"
                onChange={this.changHandler}
                value={this.state.email}
                />
              </div>

              <div className="form-group">
                <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                required="required"
                onChange={this.changHandler}
                value={this.state.subject}
                />
              </div>
            </div>

            <div className="col-12">
                <div className="form-group">
                    <textarea
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    required="required"
                    onChange={this.changHandler}
                    value={this.state.message}
                    />
                </div>
            </div>

            <div className="col-12">
                <button
                    type="submit"
                    className="btn btn-lg btn-block mt-3"><span className="text-white pr-3"><i className="fas fa-paper-plane" /></span>
                    Send Message / Request a free demo
                </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}


export default ContactForm;