import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import TrackersHero from '../HeroSection/TrackersHero';
import TrackersFeature from '../Features/TrackersFeature';
import TrackersService from '../ServiceSection/TrackersService';
import TrackersDiscover from '../DiscoverSection/TrackersDiscover';
import TrackersWork from '../WorkSection/TrackersWork';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';


class PartsPage extends Component {
      render() {
            return (
                <div className="homepage-2">
                    {/*====== Scroll To Top Area Start ======*/}
                    <div id="scrollUp" title="Scroll To Top">
                        <i className="fas fa-arrow-up" />
                    </div>
                    {/*====== Scroll To Top Area End ======*/}
                    <div className="main">
                        <Header imageData={"/img/vaptaSystems.png"} />
                        <TrackersHero />
                        <TrackersFeature />
                        <TrackersService />
                        <TrackersWork />
                        <TrackersDiscover />
                        <ContactSection />
                        <FooterSection />
                    </div>
                </div>
            );
        }
}

export default PartsPage;