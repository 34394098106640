import React, { Component } from 'react';
import HeaderAlt from '../HeaderSection/HeaderAlt';
import Breadcrumb from './Breadcrumb';
import JobSkills from './JobSkills';
import JobsDetails from './JobsDetails';
import FooterSection from '../FooterSection/Footer';

class JobDetails extends Component {
    render() {
        return (
            <div className="careers">
                {/*====== Scroll To Top Area Start ======*/}
                <div id="scrollUp" title="Scroll To Top">
                    <i className="fas fa-arrow-up" />
                </div>
                {/*====== Scroll To Top Area End ======*/}
                <div className="main">
                    <HeaderAlt imageData={"/img/vaptaSystems.png"} />
                    <Breadcrumb title="Blog Details - Left Sidebar" />
                    <section id="blog" className="section blog-area ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-3">
                                    <JobSkills />
                                </div>
                                <div className="col-12 col-lg-9">
                                    <JobsDetails />
                                </div>
                            </div>
                        </div>
                    </section>
                    <FooterSection />
                </div>
            </div>
        );
    }
}

export default JobDetails;