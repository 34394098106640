import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Renders App Component within the root of the HTML file
ReactDOM.render(
      <App />,
      document.getElementById('root')
      );

serviceWorker.unregister();