import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import PartsHero from '../HeroSection/PartsHero';
import PartsFeature from '../Features/PartsFeature';
import PartsService from '../ServiceSection/PartsService';
import PartsDiscover from '../DiscoverSection/PartsDiscover';
import PartsWork from '../WorkSection/PartsWork';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';


class PartsPage extends Component {
      render() {
            return (
                <div className="homepage-2">
                    {/*====== Scroll To Top Area Start ======*/}
                    <div id="scrollUp" title="Scroll To Top">
                        <i className="fas fa-arrow-up" />
                    </div>
                    {/*====== Scroll To Top Area End ======*/}
                    <div className="main">
                        <Header imageData={"/img/vaptaSystems.png"} />
                        <PartsHero />
                        <PartsFeature />
                        <PartsService />
                        <PartsWork />
                        <PartsDiscover />
                        <ContactSection />
                        <FooterSection />
                    </div>
                </div>
            );
        }
}

export default PartsPage;