import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";

class FeatureSection extends Component {
    state = {
        data: {},
        featureDataTwo: [],
        logoData: []
    }
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                  data: res.data.themeOneFeatureSection,
                  featureDataTwo: res.data.themeOneFeatureSection.featureDataTwo,
                  logoData: res.data.themeOneFeatureSection.logoData
                })
            //     console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="features" className="section features-area ptb_100">
                <div className="container">
                    <div className="vaptaLogosBG">
                        <ul className="vaptaLogos">
                            {this.state.logoData.map((item, idx) => {
                                return(
                                    
                                    <div className="vaptaPics" key={`fth_${idx}`}>
                                        <img src={item.image} className="vaptaLogoLine"/>
                                    </div>
                                
                                );
                            })}
                        </ul>
                    </div>
                    <div className="DVSAImage">
                        <h3>FORS & DVSA Accredited fleet software</h3>

                        <div className="forsDvsa">
                            <img src={this.state.data.DVSA} alt=""/>
                            <img src={this.state.data.FORS} alt=""/>
                        </div>   
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2>Transform the way you manage your fleet.</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingText}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    {this.state.featureDataTwo.map((item, idx) => {
                        return(
                            <div key={`ft_${idx}`} className="col-12 col-md-6 col-lg-4">
                                {/* Icon Box */}
                                <div className="icon-box text-center p-4">
                                {/* Featured Icon */}
                                <div className="featured-icon mb-3">
                                    <span className={item.iconClass} />
                                </div>
                                {/* Icon Text */}
                                <div className="icon-text">
                                    <h3 className="mb-2">{item.title}</h3>
                                    <p>{item.text}</p>
                                </div>
                                </div>
                            </div>
                        );
                    })}
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureSection;