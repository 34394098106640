import React, { Component } from 'react';
import axios from 'axios';

// const jsonServer = "https://vapta.co.uk/json-server/db.json";
const jsonServer = "https://development.vapta.co.uk/json-server/db.json";


class ContactSection extends Component {
    state = {
        data: {},
        iconList: []
    }
    componentDidMount(){
        axios.get(`${jsonServer}`)
            .then(res => {
                this.setState({
                    data: res.data.WelcomeJobSection,
                    iconList: res.data.WelcomeJobSection.iconList
                })
                console.log(this.state.iconList)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section id="contact" className="contact-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize smaller">{this.state.data.heading}</h2>
                        <p className="d-none d-sm-block mt-4">{this.state.data.headingText}</p>
                        <p className="d-block d-sm-none mt-4">{this.state.data.headingText}</p>
                        </div>
                    </div>
                    </div>
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-6">
                        {/* Contact Us */}
                        <div className="contact-us">
                        <p className="mb-3 ml-5 align-self-center font-weight-bold">{this.state.data.content}</p>
                        <ul className="bulletPoints">
                            {this.state.iconList.map((item, idx) => {
                                return( 
                                    <li key={`fth_${idx}`}>
                                        {/* Image Box */}
                                        <div className="image-box media px-1 py-1 py-md-2">
                                        {/* Featured Image */}
                                        <div className="featured-img mr-1 careerImage">
                                              <div className={item.iconClass} id="featuresIcons"></div>
                                        </div>
                                        {/* Icon Text */}
                                        <div className="icon-text media-body align-self-center align-self-md-start">
                                            <h3 className="mb-2">{item.title}</h3>
                                            <p>{item.text}</p>
                                        </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pt-4 pt-md-0">
                        <p className="mb-3 align-self-center font-weight-bold py-md-2">{this.state.data.contentTwo}</p>
                        <p className="mb-3 align-self-center py-md-2">{this.state.data.contentThree}</p>
                        <p className="mb-3 align-self-center font-weight-bold py-md-2">{this.state.data.contentFour}</p>
                        <p className="mb-3 align-self-center py-md-2">{this.state.data.contentFive}</p>
                        <a href="" className="btn">Join our team</a>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;