import React, { Component } from 'react';

const data = [
    {
        id: "1",
        avatorImg: "/img/heanorFleetServicesLogo.png",
        reviewer: "Heanor Fleet Serive",
        address: "Kingston on Soar, Nottingham",
        text: "We’ve been using the Vapta inspection software and the system is a great tool for organising and planning work. Customer support is excellent. The best part is the customisation and tweaks to the system to suit the needs of their customers. We highly recommend this system and coupled with the other services they provide like telematics etc makes it a complete package.",
        icon_1: "fas fa-star",
        icon_2: "fas fa-star",
        icon_3: "fas fa-star",
        icon_4: "fas fa-star",
        icon_5: "fas fa-star"
    },
    {
        id: "2",
        avatorImg: "/img/GHFleetServices.jpg",
        reviewer: "G.H Fleet Services",
        address: "Melton, Hull.",
        text: "The VAPTA system is a easy to use one stop shop for all fleet management needs. This software has allowed me to keep a detailed log of all vehicles, defects and costs. The software was tailored to my requirements to give a clear and detailed inspection sheet for my customers to read. With the simple design, it makes it very simple to use on the go on mobile devices and a pc. I would highly recommend you use VAPTA for your fleet reequipments, the team are very professional and approachable.",
        icon_1: "fas fa-star",
        icon_2: "fas fa-star",
        icon_3: "fas fa-star",
        icon_4: "fas fa-star",
        icon_5: "fas fa-star"
    },
    {
        id: "3",
        avatorImg: "/img/rodgersCoaches.png",
        reviewer: "Rodgers Coaches Ltd",
        address: "Weldon, Corby.",
        text: "'We have found Vapta to be very a professional, helpful company with user friendly software. Having never used software to carry out maintenance inspections before we did have some concerns but these were very quickly addressed by Vapta and made the crossover between paper records to using the software very easy and seamless. We would highly recommend Vapta Fleet Management Software.",
        icon_1: "fas fa-star",
        icon_2: "fas fa-star",
        icon_3: "fas fa-star",
        icon_4: "fas fa-star",
        icon_5: "fas fa-star"
    }
]

class ReviewSection extends Component {
    render() {
        return (
            <section className="section testimonial-area ptb_100" id="testimonials">
                <div className="container text-center">
                    <div className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className="testimonials owl-carousel">
                        {/* Single Testimonial */}
                        {data.map((item, idx) => {
                            return(
                                <div key={`rt_${idx}`} className="single-testimonial p-3 p-md-5">
                                    <img src={item.avatorImg} className="mx-auto d-block" alt="" />
                                    {/* Client Name */}
                                    <h3 className="client-name mt-4 mb-2">{item.reviewer}</h3>
                                    {/* Client Address */}
                                    <h5 className="client-address fw-4">{item.address}</h5>
                                    {/* Client Rating */}
                                    <div className="client-rating mt-2 mb-3">
                                    <i className={item.icon_1} />
                                    <i className={item.icon_2} />
                                    <i className={item.icon_3} />
                                    <i className={item.icon_4} />
                                    <i className={item.icon_5} />
                                    </div>
                                    {/* Client Description */}
                                    <div className="client-description">
                                    {/* Client Text */}
                                    <div className="client-text">
                                        <p>{item.text}</p>
                                    </div>
                                    </div>
                                </div>
                            );
                        })}
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ReviewSection;