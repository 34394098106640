import React, { Component } from 'react';
import Header from '../HeaderSection/Header';
import InspectorHero from '../HeroSection/InspectorHero';
import InspectorFeature from '../Features/InspectorFeature';
import InspectorService from '../ServiceSection/InspectorService';
import InspectorDiscover from '../DiscoverSection/InspectorDiscover';
import InspectorWork from '../WorkSection/InspectorWork';
import ContactSection from '../ContactSection/Contact';
import FooterSection from '../FooterSection/Footer';


class PartsPage extends Component {
      render() {
            return (
                <div className="homepage-2">
                    {/*====== Scroll To Top Area Start ======*/}
                    <div id="scrollUp" title="Scroll To Top">
                        <i className="fas fa-arrow-up" />
                    </div>
                    {/*====== Scroll To Top Area End ======*/}
                    <div className="main">
                        <Header imageData={"/img/vaptaSystems.png"} />
                        <InspectorHero />
                        <InspectorFeature />
                        <InspectorService />
                        <InspectorWork />
                        <InspectorDiscover />
                        <ContactSection />
                        <FooterSection />
                    </div>
                </div>
            );
        }
}

export default PartsPage;